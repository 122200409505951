import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { ManagedBannerWrapperProps } from "./typings";
import { ManagedBanner } from "@shared-ui/retail-managed-banner";

export const ManagedBannerWrapper = withStores("flexModuleModelStore")(
  observer((props: ManagedBannerWrapperProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    if (!templateComponent?.config) {
      return null;
    }

    const { contentTopic } = templateComponent.config;
    const { metadata } = templateComponent;
    const { id } = metadata;

    return (
      <div data-testid={id} id={id}>
        <ManagedBanner
          inputs={{
            contentTopic,
          }}
        />
      </div>
    );
  })
);

export default ManagedBannerWrapper;
